// FontAwesome (for the icons)
require('@fortawesome/fontawesome-free/css/all.css');
require('@fortawesome/fontawesome-free/js/all.js');

// Main SASS File
require('../sass/app.scss');

// jQuery available
const $ = require('jquery');

// Bootstrap JS
require('bootstrap');

// Inputmask
require('inputmask');
require('inputmask/dist/jquery.inputmask.js');

// Autocomplete
require('devbridge-autocomplete');

// Datepicker
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.fr.min');

// Telephone
require('intl-tel-input');
require('intl-tel-input/build/js/intlTelInput-jquery.min.js');
require('intl-tel-input/build/css/intlTelInput.css');

// JS Cookie
global.Cookies = require('js-cookie');

// AGFA JS
require('./hpa.js');
require('./components/share.js');
require('./components/transfer.js');
require('./components/cookies');
