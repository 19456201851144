var siteLocale = document.documentElement.lang;

switch (siteLocale) {
    case 'en':
        var messageHtmlLoading = '<div class="alert alert-info">Loading...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Sorry, there was a problem with your request. Try to reconnect.</div>';
        var messageAreYouSure = 'Are you sure?';
        break;
    case 'fr':
        var messageHtmlLoading = '<div class="alert alert-info">Chargement en cours...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Désolé, il y a eu un probleme avec votre demande. Essayez de vous reconnecter.</div>';
        var messageAreYouSure = 'Etes-vous sûr ?';
        break;
    default:
        var messageHtmlLoading = '<div class="alert alert-info">Loading...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Sorry, there was a problem with your request. Try to reconnect.</div>';
        var messageAreYouSure = 'Are you sure?';
        break;
}

// Show the form anyways when the payments exist
$(document).on('click', '.btn-transfer-anyways', function (evt) {
    evt.stopImmediatePropagation();
    $('.transfer-form-undaid').addClass('hide');
    $('.transfer-form').removeClass('hide');
});

// Reload the page when closing the modal window
$('#modal-transfer-person').on('hidden.bs.modal', function (evt) {
    evt.stopImmediatePropagation();
    location.reload(true);
})

// ADMIN AREA
$(document).on('click', '.badge-transfer', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-transfer .modal-body').html(html);
            $('#modal-edit-transfer').modal();
        });
});

$(document).on('click', '.close-modal-transfer', function (evt) {
    $('#modal-edit-transfer').modal('hide');
});

$(document).on('click', '.btn-delete-transfer', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (confirm(messageAreYouSure)) {
        $.ajax($(this).data('url'))
            .done(function(data) {
                if (data.deleted) {
                    location.reload();
                } else {
                    var html = (data.html) ? data.html : messageHtmlErrorConnection;
                    $('#modal-edit-transfer .modal-body').html(html);
                }
            });
    }
});
