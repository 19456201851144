var AGFA = {
    debug : false,
    modules : {}
};

var siteLocale = document.documentElement.lang;

switch (siteLocale) {
    default:
    case 'en':
        var messageHtmlLoading = '<div class="alert alert-info">Loading...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Sorry, there was a problem with your request. Try to reconnect.</div>';
        var messageAreYouSure = 'Are you sure?';
        break;
    case 'fr':
        var messageHtmlLoading = '<div class="alert alert-info">Chargement en cours...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Désolé, il y a eu un probleme avec votre demande. Essayez de vous reconnecter.</div>';
        var messageAreYouSure = 'Etes-vous sûr ?';
        break;
}


/**
 * créé un module AGFA
 *
 * @param module
 *            Function
 */
function agfa(nom, module) {
    var objet = AGFA.modules[nom] = new module();

    /**
     * ici on initialise ce qu'il faut pour les autres méthodes le DOM n'est pas
     * chargé
     */
    if (typeof objet.init === 'function') {
        if (AGFA.debug)
            console.log('AGFA ' + nom + ' init');
        objet.init();
    }

    /**
     * ici on continue à initialiser le DOM est chargé
     */
    if (typeof objet.ready === 'function')
        $.when($.ready).then(function() {
            if (AGFA.debug)
                console.log('AGFA ' + nom + ' ready');
            objet.ready();
        });
}

function eyesPasswords() {
    $('input[type="password"]:visible, input[type="password"]#enrolement_password').each(
        function(i, elt) {
            if ($(elt).parents('.form-group').first().find('.reveal-password').length <= 0) {
                $(elt)
                .wrap('<div class="input-group"></div>')
                .after(
                        '<span class="input-group-btn"><button class="btn btn-default reveal-password" type="button" data-target="'
                        + $(elt).attr('id')
                        + '"><i class="fa fa-eye"></i></button></span>');
            }
        });
}

$(function() {

    eyesPasswords();

    $('body').on('click', 'button.reveal-password',
        function(event) {
            if ($(this).data('action') === 'hide') {
                $('#' + $(this).data('target')).attr('type', 'password');
                $(this).data('action', 'show').find('.fa').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
            } else {
                $('#' + $(this).data('target')).attr('type', 'text');
                $(this).data('action', 'hide').find('.fa').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
            }
    });

    $('[data-toggle="popover"]').popover();

    $('body').on('click', '.btn-remove-trusted-device', function(event) {
        var row = $(this).closest('tr');
        $.ajax({
            url: $(this).data('url'),
            success: function(data) {
                $(row).remove();
            }
        });
    });

    $('body').on('submit', '#user_security_check', function(e) {
        InputSetCustomValidity($('#user_security_check input#password')[0],e);
    });

    $('body').on('submit', '#user_resetting_request', function(e) {
        InputSetCustomValidity($('#user_resetting_request input#username')[0],e);
    });
    $('body').on('click', '.user-disabled', function(e) {
        e.preventDefault();
    });

    $('body').on('submit', '.js-frm-consulter-direct', function(e) {
        var theForm = this;
        var $button = $('.js-btn-consulter-direct');
        e.preventDefault();
        $('body').css({'cursor': 'wait'});
        $button.css({
            'cursor': 'wait'
            , 'display': 'none'
        });
        switch (siteLocale) {
            default:
            case 'en':
                $button.after('<span class="span171">Loading...</span>');
                break;
            case 'fr':
                $button.after('<span class="span171">Chargement en cours...</span>');
                break;
        }
        setTimeout(function () { theForm.submit(); }, 500);
    });

    if(navigator.cookieEnabled === false){
        $('#cookie-bar').removeClass('hide');
    }

    // Ensure that 2 menus won't show up at the same time on small devices
    $('body').on('click', '#button-navbar-xs', function(event) {
        $("#button-navbar-profile-xs").attr("aria-expanded","false");
        $("#button-navbar-calendar-xs").attr("aria-expanded","false");
        $("#navbar-profile-xs").removeClass("in");
        $("#navbar-calendar-xs").removeClass("in");

    });
    $('body').on('click', '#button-navbar-profile-xs', function(event) {
        $("#button-navbar-xs").attr("aria-expanded","false");
        $("#button-navbar-calendar-xs").attr("aria-expanded","false");
        $("#navbar-xs").removeClass("in");
        $("#navbar-calendar-xs").removeClass("in");
    });
    $('body').on('click', '#button-navbar-calendar-xs', function(event) {
        $("#button-navbar-xs").attr("aria-expanded","false");
        $("#button-navbar-profile-xs").attr("aria-expanded","false");
        $("#navbar-xs").removeClass("in");
        $("#navbar-profile-xs").removeClass("in");
    });

    // Set the transfer & share accordion opened when landing on the page
$('#accordion_transfer').click();
$('#accordion_share').click();
});

$('[data-toggle="tooltip"]').tooltip();

$(document).on('click', '#accordion_transfer', function(evt){
    var isExpanded_transfer = $('#accordion_transfer').hasClass("collapsed");
    if(isExpanded_transfer) {
        $('.up').hide();
        $('.down').show();
    } else {
        $('.up').show();
        $('.down').hide();
    }
});
$(document).on('click', '#accordion_share', function(evt){
    var isExpanded_transfer = $('#accordion_share').hasClass("collapsed");
    if(isExpanded_transfer) {
        $('.up').hide();
        $('.down').show();
    } else {
        $('.up').show();
        $('.down').hide();
    }
});

// Rend la selection d'une personne entièrement clickable
$(".liste-selection-personne").click(function() {
    window.location = $(this).find("a").attr("href");
    return false;
});

// Click sur un object triggers le premier lien trouvé avec la class clickme
$(".clickme").click(function(e) {
    var link = $(this).find("a.clickmelink").get(0);
    link.click();
    e.stopPropagation();
});

// Show and hide more details from reports list page
$('.contentReportPrimary .col-md-8').find('.btn').click(function(e){e.stopPropagation();});
$('.contentReportPrimary .col-md-8').find('div.purge-alert').click(function(e){e.stopPropagation();});
$('.contentReportPrimary .col-md-8').click(function(e){
    e.preventDefault();

    $(this).find('.btn').click(function(e){e.stopPropagation();});
    $(this).find('div.purge-alert').click(function(e){e.stopPropagation();});

    // get id of report to show
    var dataId = $(this).data('id');

    $('.contentReportPrimary'+dataId).fadeOut("fast", function(){
       $('.contentReportSecondary'+dataId).fadeIn("fast");
       $('.contentReportSecondary'+dataId).find('.btn').click(function(e){e.stopPropagation();});
       $('.contentReportSecondary'+dataId).find('div.purge-alert').click(function(e){e.stopPropagation();});
    });
});

$('.contentReportSecondary').click(function(e){
    e.preventDefault();

    // get id of report to show
    var dataId = $(this).data('id');

    $('.contentReportSecondary'+dataId).fadeOut("fast", function(){
       $('.contentReportPrimary'+dataId).fadeIn("fast");
    });
});

// For mobile devices
$('.contentReportPrimarySm .col-xs-12').find('.btn').click(function(e){e.stopPropagation();});
$('.contentReportPrimarySm .col-xs-12').find('div.purge-alert').click(function(e){e.stopPropagation();});
$('.contentReportPrimarySm .col-xs-12').click(function(e){
    e.preventDefault();

    $(this).find('.btn').click(function(e){e.stopPropagation();});
    $(this).find('div.purge-alert').click(function(e){e.stopPropagation();});

    // get id of report to show
    var dataId = $(this).data('id');

    $('.contentReportPrimarySm'+dataId +','+' .contentReportPrimarySm'+dataId+' .reports-list-left-sm').fadeOut("fast", function(){
        $('.contentReportSecondarySm'+dataId).fadeIn("fast");
        $('.contentReportSecondarySm'+dataId).find('.btn').click(function(e){e.stopPropagation();});
        $('.contentReportSecondarySm'+dataId).find('div.purge-alert').click(function(e){e.stopPropagation();});
    });
});

$('.contentReportSecondarySm').click(function(e){
    e.preventDefault();

    // get id of report to show
    var dataId = $(this).data('id');

    $('.contentReportSecondarySm'+dataId).fadeOut("fast", function(){
           $('.contentReportPrimarySm'+dataId).fadeIn("fast");
           $('.contentReportPrimarySm'+dataId+' .reports-list-left-sm').fadeIn("fast");
    });
});


// Read reports
$('.cr-read-short').find('.btn').click(function(e){e.stopPropagation();});
$('.cr-read-extended').find('.btn').click(function(e){e.stopPropagation();});
$('.cr-read-short').find('div.purge-alert').click(function(e){e.stopPropagation();});
$('.cr-read-extended').find('div.purge-alert').click(function(e){e.stopPropagation();});

$('.cr-read-short').click(function(e){
    e.preventDefault();

    $(this).find('.btn').click(function(e){e.stopPropagation();});
    $(this).find('div.purge-alert').click(function(e){e.stopPropagation();});

    // get id of report to show
    var dataId = $(this).data('id');

    $('.cr-read-short'+dataId).fadeOut("fast", function(){
       $('.cr-read-extended'+dataId).fadeIn("fast");
       $('.cr-read-extended'+dataId).find('.btn').click(function(e){e.stopPropagation();});
       $('.cr-read-extended'+dataId).find('div.purge-alert').click(function(e){e.stopPropagation();});
    });
});

$('.cr-read-extended').click(function(e){
    e.preventDefault();

    // get id of report to show
    var dataId = $(this).data('id');

    $('.cr-read-extended'+dataId).fadeOut("fast", function(){
       $('.cr-read-short'+dataId).fadeIn("fast");
    });
});

// New CRs
$(document).on('click', '.crendu-left', function(evt){
    $(this).parents('.crendu').first().toggleClass('crendu-extended');
});

// Clearing form fields has to be delayed to prevent Lastpass or other module to fill fields once the page is loaded.
setTimeout(function(){
    $('form').not('[name="recherche_demande"]').each(function() {
    this.reset();
    });
}, 500);

// EDIT SUPPORT FORM
// Load the modal when editing the information of a support user
$(document).on('click', '.btn-edit-support', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-support .modal-body').html(html);
            $('#modal-edit-support').modal();
            addClassRolesCheckboxes();
            toogleHeaderAllowedIps();
            loadTelephones();
        });
    $('#modal-edit-support').on('shown.bs.modal', function(evt){
        eyesPasswords();
    });
});

// Manage the allowedIps form to add or delete IPs.
$(document).on('click', '.allowedIps-form .btn-add', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    var html = $(this).parents('.allowedIps-form').first().data('prototype');
    var newIndex = $('#modal-edit-support .modal-body .allowedIps-fields > div').length + 1;
    html = html.replace(/__name__/g, newIndex);
    $('#modal-edit-support .modal-body .allowedIps-fields').append(html);
    toogleHeaderAllowedIps();
});

// Remove an allowedIp from the modal window
$(document).on('click', '.allowedIps-form .btn-delete', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $(this).parents('.allowedIps-field').first().remove();
    toogleHeaderAllowedIps();
});

//force tp add IP if role != patient + Clean IPs if role = patient
$('body').on('submit', '#form_edit_support form', function(evt) {
    evt.preventDefault();

    // Roles
    var roles = $('input[id*="user_support_roles_"]:checked');
    roles = (roles.length > 0) ? roles : $('input[id*="user_support_password_roles_"]:checked');
    var rolesToGet = roles[0];
    if (rolesToGet) {
        switch (rolesToGet.value) {
            case 'ROLE_SUPPORT_1':
            case 'ROLE_SUPPORT_2':
            case 'ROLE_ADMIN':
                var allowedIps_input = $('.allowedIps-field input[id$="_ip"]');
                if(allowedIps_input.length == 0) {
                    var $button = $('#form_edit_support form .btn-add').trigger('click');
                    return false;
                }
                break;
            case 'ROLE_PATIENT':
                var allowedIps_buttonDelete = $('.allowedIps-field span').hasClass("btn-delete");
                if(allowedIps_buttonDelete.length != 0) {
                    $('.allowedIps-field span').each(function (params) {
                        $(this).trigger('click');
                    });
                }
                break;
        }
    }

    // Telephone
    var fieldsTelephone = ['#user_support_password_telephone', '#user_support_telephone'];
    $(fieldsTelephone).each(function(index, ele){
        if ($(ele).length > 0) {
            $(ele).val($(ele).intlTelInput("getNumber"));
        }
    });
});

// Submit the form with the allowedIps and roles
$(document).on('submit', '#form_edit_support form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_support').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_support').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_support').replaceWith(data.html);
            } else {
                $('#form_edit_support').replaceWith(messageHtmlErrorConnection);
            }
            addClassRolesCheckboxes();
            eyesPasswords();
            loadTelephones();
        }
    });
});

// Stepper component for the roles
$(document).on('change', '.roles-checkboxes input', function(evt){
    $('.roles-checkboxes input').prop('checked', false);
    $(this).prop('checked', true);
    addClassRolesCheckboxes();
});

// EDIT SUPPORT EJs FORM
// Select laboratories EJs
function filterLaboratories() {
    var selectedEJs = [];
    $('.ej_laboratories_form_trigger input').each(function(index, ele){
        if ($(ele).is(":checked")) {
            selectedEJs.push($(ele).val());
        }
    });
    $('.ej_laboratories_form_laboratories select option').show();
    if ($('.ej_laboratories_form_laboratories select').val()) {
        var actualSelection = $('.ej_laboratories_form_laboratories select').val().split('_');
        if (!selectedEJs.includes(actualSelection[0])) {
            $('.ej_laboratories_form_laboratories select').val('');
        }
    }
    if (selectedEJs.length > 0) {
        $('.ej_laboratories_form_laboratories').show();
        $('.ej_laboratories_form_laboratories select option').each(function(index, ele){
            selectValue = $(ele).val().split('_');
            if (!selectedEJs.includes(selectValue[0])) {
                $(ele).hide();
            }
        })
    } else {
        $('.ej_laboratories_form_laboratories').hide();
    }
}

// Load the modal when editing the information of a support user
$(document).on('click', '.btn-edit-ej', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-ej .modal-body').html(html);
            $('#modal-edit-ej').modal();
            filterLaboratories();
        });
});
// Change the list of laboratories
$(document).on('change', '.ej_laboratories_form_trigger input', function(evt){
    filterLaboratories();
});



// Submit the form with the allowedEJs and roles
$(document).on('submit', '#form_edit_support form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_support').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_support').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_support').replaceWith(data.html);
            } else {
                $('#form_edit_support').replaceWith(messageHtmlErrorConnection);
            }
            addClassRolesCheckboxes();
        }
    });
});


// EDIT EMAIL FORM
// Manage the update form for the emails.
$(document).on('click', '.btn-edit-email', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-email .modal-body').html(html);
            $('#modal-edit-email').modal();
        });
});

// EDIT REPORT FORM
// Manage the display of reports on small devices
$(document).on('click', '.btnReport-OpenModal', function (evt) {
    $('#ModalaccordionFilter').modal();
});

// Submit the form with the updated email
$(document).on('submit', '#form_edit_email form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_email').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_email').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_email').replaceWith(data.html);
            } else {
                $('#form_edit_email').replaceWith(messageHtmlErrorConnection);
            }
        }
    });
});


// EDIT TRANSFERS FORM
// Manage the transfers form
$(document).on('click', '.btn-edit-transfers', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-transfers .modal-body').html(html);
            $('#modal-edit-transfers').modal();
        });
});

// Submit the form with the updated email
$(document).on('submit', '#form_edit_transfers form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_transfers').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_transfers').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_transfers').replaceWith(data.html);
            } else {
                $('#form_edit_transfers').replaceWith(messageHtmlErrorConnection);
            }
        }
    });
});

// EDIT PERSONNE
$(document).on('click', '.btn-edit-personne', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-personne .modal-body').html(html);
            $('#modal-edit-personne').modal();
        });
});

$(document).on('submit', '#form_edit_personne form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_personne').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_personne').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_personne').replaceWith(data.html);
            } else {
                $('#form_edit_personne').replaceWith(messageHtmlErrorConnection);
            }
        }
    });
});

// EDIT PATIENT
$(document).on('click', '.btn-edit-patient', function (evt) {
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-patient .modal-body').html(html);
            $('#modal-edit-patient').modal();
            loadTelephones();
        });
});

$(document).on('submit', '#form_edit_patient form', function(evt){
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $('#agfa_hpabundle_patient_telephone').val($('#agfa_hpabundle_patient_telephone').intlTelInput("getNumber"));
    $.ajax({
        type: "POST",
        url: $(this).attr('action'),
        data: $(this).serialize(),
        beforeSend: function() {
            $('#form_edit_patient').html(messageHtmlLoading);
        },
        error: function() {
            $('#form_edit_patient').html(messageHtmlErrorConnection);
        },
        success: function(data) {
            if (data.persisted) {
                location.reload();
            } else if (data.html) {
                $('#form_edit_patient').replaceWith(data.html);
            } else {
                $('#form_edit_patient').replaceWith(messageHtmlErrorConnection);
            }
        }
    });
});

// View OTP
$(document).on('click', '.btn-otp', function(evt){
    $.ajax($(this).data('url'))
    .done(function(data) {
        if (data && data.otp) {
            alert('OTP : ' + data.otp);
        }
    });
});

function addClassRolesCheckboxes() {
    var maxValue = 0;
    $('.roles-checkboxes .checkbox').each(function(index, ele){
        maxValue = ($(ele).find('input').is(':checked')) ? $(ele).index() : maxValue;
    });
    $('.roles-checkboxes .checkbox').removeClass('selected');
    $('.roles-checkboxes .checkbox').removeClass('selectedActive');
    $('.roles-checkboxes .checkbox').slice(0, maxValue+1).addClass('selected');
    $('.roles-checkboxes .checkbox:nth-child(' + (maxValue+1) + ')').addClass('selectedActive');
}

function toogleHeaderAllowedIps() {
    $('.allowedIps-field-header').toggle(($('.allowedIps-field').length>0));
}

// In the pagination component, prevent the click on the active page and on the disabled buttons
$('.pagination li.active a, .pagination li.disabled a').on('click', function (e) {
    e.preventDefault();
    return false;
});

// All 'danger' btn ask for a confirmation to the usr before moving on
$(document).on('click', 'a.btn-danger, .btn-alert', function(evt){
    return confirm(($(this).data('alert')) ? $(this).data('alert') : messageAreYouSure);
});

// Mark all notifications as read
$(document).on('click', '.badge-notifications', function(evt){
    $.get({url: $(this).data('url')});
    $(this).fadeOut();
});

// Fix for all the tab navigations and the anchors in the URL
$(function(){
  var hash = window.location.hash;
  hash && $('ul.nav a[href="' + hash + '"]').tab('show');

  $('.nav-tabs a').click(function (e) {
    $(this).tab('show');
    var scrollmem = $('body').scrollTop();
    window.location.hash = this.hash;
    $('html,body').scrollTop(scrollmem);
  });
});

agfa('Help', function () {
    var oHelp = this;
    switch (siteLocale) {
        default:
        case 'en':
            oHelp.messages =
                [
                    {
                        elt: 'a.navbar-brand',
                        txt: "This link takes you back to the homepage",
                        pos: "bottom"
                    },
                    {
                        elt: '#lien-comptes-rendus',
                        txt: "This link will take you to the full list of reports.",
                        pos: "bottom"
                    },
                    {
                        elt: '#compteLabel',
                        txt: "Click here to log out.",
                        pos: "bottom"
                    },
                    {
                        elt: '#lien-recherche',
                        txt: "Click here to search for reports by date or exam.",
                        pos: "bottom"
                    }
                ];
            break;
        case 'fr':
            oHelp.messages =
                [
                    {
                        elt: 'a.navbar-brand',
                        txt: "Ce lien vous ramène à l'accueil.",
                        pos: "bottom"
                    },
                    {
                        elt: '#lien-comptes-rendus',
                        txt: "Ce lien vous amène à la liste complète des comptes rendus.",
                        pos: "bottom"
                    },
                    {
                        elt: '#compteLabel',
                        txt: "Cliquez ici pour vous déconnecter.",
                        pos: "bottom"
                    },
                    {
                        elt: '#lien-recherche',
                        txt: "Cliquez ici pour rechercher des comptes rendus par date ou par examen.",
                        pos: "bottom"
                    }
                ];
            break;
    }

    oHelp.next_message_index = 0;
    oHelp.current_element = null;

    /**
     * ici on initialise ce qu'il faut pour les autres méthodes
     * le DOM n'est pas chargé
     */
    oHelp.init = function () {
    }

    /**
     * ici on continue à initialiser
     * le DOM est chargé
     */
    oHelp.ready = function () {
    }

    /**
     * mise en place des handlers
     */
    oHelp.bindEvents = function () {
        $('body').on('click', '.tooltip-inner-content .btn-suivant', oHelp.next);
        $('body').on('click', '.tooltip-inner-content .btn-quitter', oHelp.stop);
        $('body').on('keydown', oHelp.keyDown);
    }

    /**
     * nettoyage des handlers
     */
    oHelp.unbindEvents = function () {
        $('body').off('click', '.tooltip-inner-content .btn-suivant', oHelp.next);
        $('body').off('click', '.tooltip-inner-content .btn-quitter', oHelp.stop);
        $('body').off('keydown', oHelp.keyDown);
    }

    /**
     * traitement des touches clavier
     */
    oHelp.keyDown = function (event) {
        if(event.keyCode === 27) oHelp.stop();
    }

    /**
     * l'utilisateur a demandé l'aide
     */
    oHelp.start = function () {
        oHelp.bindEvents();
        oHelp.affiche_overlay('#000000', 0.5);
        oHelp.next_message_index = 0;
        oHelp.next();
    }

    /**
     * fin de l'aide, soit à la demande de l'utilisateur, soit automatiquement
     */
    oHelp.stop = function () {
        console.log("AGFA Help stop");
        oHelp.masque_aide_element();
        oHelp.masque_overlay();
        oHelp.unbindEvents();
    }

    /**
     * prochain tooltip
     */
    oHelp.next = function () {
        console.log("AGFA Help next index: "+oHelp.next_message_index);
        if(oHelp.next_message_index === oHelp.messages.length) {
            oHelp.stop();
            return;
        }
        oHelp.masque_aide_element();
        var message = oHelp.messages[oHelp.next_message_index++];
        if(!message || $(message.elt).length === 0) oHelp.next();
        oHelp.affiche_aide_element($(message.elt), message.txt, message.pos);
    }

    /**
     * affichage du masque sombre sur la page
     */
    oHelp.affiche_overlay = function (color, opacity) {
        oHelp.overlay = $('<div class="full-overlay"></div>')
            .appendTo('body')
            .css('backgroundColor', color)
            .animate({
                opacity: opacity
            }, 'slow');
    }

    /**
     * suppression du masque sombre
     */
    oHelp.masque_overlay = function () {
        oHelp.overlay.animate({
            opacity: 0
        }, 'fast', function () {
            oHelp.overlay.remove();
        });
    }

    /**
     * affichage de l'aide sur un élément
     */
    oHelp.affiche_aide_element = function (element, text, placement) {

        var localQuit = "";
        var localNext = "";

        switch (siteLocale) {
            default:
            case 'en':
                localQuit = 'Quit';
                localNext = 'Next';
                break;
            case 'fr':
                localQuit = 'Quitter';
                localNext = 'Suivant';
                break;
        }

        oHelp.current_element = $(element).tooltip({
            title: '<div class="tooltip-inner-content"><p>'+text+'</p><div class="btn-toolbar" role="toolbar"><button class="btn btn-primary btn-xs btn-suivant">'+localNext+'</button><button class="btn btn-default btn-xs btn-quitter">'+localQuit+'</button></div></div>',
            html: true,
            container: 'body',
            placement: placement,
            trigger: 'manual'
        }).tooltip('show');
    }

    /**
     * suppression de l'aide sur un élément
     */
    oHelp.masque_aide_element = function (element) {
        if(!element) element = oHelp.current_element;
        delete oHelp.current_element;
        if(!element) return;
        element.tooltip('hide');
    }
});
$(function(){
    $('input[data-maskactive="true"]').inputmask({
        alias: "datetime",
        placeholder: ((siteLocale == 'en') ? 'DD/MM/YYYY' : 'JJ/MM/AAAA'),
        inputFormat: "dd/mm/yyyy"
    });
});

// Password
$(function(){
    var inputPassword = $('#registration_form_plainPassword_first');
    var inputPasswordChange = $('#change_password_form_plainPassword_first');
    var inputPasswordReset = $('#resetting_form_plainPassword_first');
    var inputPasswordShare = $('#repeated_password_plainPassword_first');

    var checkPasswordValue = function(input) {
        $('.password-message').addClass('password-message-checked');
        var value = input.val();
        var accepted = true;
        if (value.length > 0) {
            if (value.length < 8) {
                accepted = false;
                $('.password-message-8').removeClass('password-message-checked');
            } else {
                $('.password-message-8').addClass('password-message-checked');
            }
            if (/[a-z]/.test(value) == false) {
                accepted = false;
                $('.password-message-lower').removeClass('password-message-checked');
            } else {
                $('.password-message-lower').addClass('password-message-checked');
            }
            if (/[A-Z]/.test(value) == false) {
                accepted = false;
                $('.password-message-upper').removeClass('password-message-checked');
            } else {
                $('.password-message-upper').addClass('password-message-checked');
            }
            if (/[0-9]/.test(value) == false) {
                accepted = false;
                $('.password-message-number').removeClass('password-message-checked');
            } else {
                $('.password-message-number').addClass('password-message-checked');
            }
        } else {
            $('.password-message-8').removeClass('password-message-checked');
            $('.password-message-lower').removeClass('password-message-checked');
            $('.password-message-upper').removeClass('password-message-checked');
            $('.password-message-number').removeClass('password-message-checked');
        }

        if (accepted) {
            input.parents('.form-group').removeClass('has-error');
        } else {
            input.parents('.form-group').addClass('has-error');
        }
    }
    inputPassword.on('keyup', function(){
        checkPasswordValue(inputPassword);
    });
    inputPasswordChange.on('keyup', function(){
        checkPasswordValue(inputPasswordChange);
    });
    inputPasswordReset.on('keyup', function(){
        checkPasswordValue(inputPasswordReset);
    });
    inputPasswordShare.on('keyup', function(){
        checkPasswordValue(inputPasswordShare);
    });
});


function InputSetCustomValidity(input,event) {

    input.setCustomValidity('');
    var inputVal = $(input).val();

    if(inputVal.length  == 0){
        switch (siteLocale) {
            default:
            case 'en':
                input.setCustomValidity('Please complete this field.');
                event.preventDefault();
                break;
            case 'fr':
                input.setCustomValidity('Veuillez renseigner ce champ.');
                event.preventDefault();
                break;
        }
        input.reportValidity();
    }
}


// Adresse
$(function(){
    if ($('#adresse_form').length > 0) {
        $(document).on('input', '#adresse_cp', function(evt){
            if ($(this).val() && $(this).val().length == 5 && !$('#adresse_ville').val() && $('#adresse_pays').val() == 'FR') {
                $.ajax({
                    url: 'https://geo.api.gouv.fr/communes?codePostal=' + $(this).val() + '&fields=nom&format=json',
                    success: function(data) {
                        if (data && data[0] && data[0].nom) {
                            $('#adresse_ville').val(data[0].nom);
                        }
                    }
                });
            }
        });
        $(document).on('input', '#demande_direct_on_pay_cp', function(evt){
            if ($(this).val() && $(this).val().length == 5 && !$('#demande_direct_on_pay_ville').val() && $('#demande_direct_on_pay_pays').val() == 'FR') {
                $.ajax({
                    url: 'https://geo.api.gouv.fr/communes?codePostal=' + $(this).val() + '&fields=nom&format=json',
                    success: function(data) {
                        if (data && data[0] && data[0].nom) {
                            $('#demande_direct_on_pay_ville').val(data[0].nom);
                        }
                    }
                });
            }
        });
    }
});

$(function(){
    $(document).on('keyup', '#user_email_newEmail_first', function (evt) {
        if($('#user_email_newEmail_first').validity.typeMismatch) {
            $('#user_email_newEmail_first').setCustomValidity("En attente de mail");
        } else {
            $('#user_email_newEmail_first').setCustomValidity("");
        }
    });
});

$(function(){
    $(document).on('change', '#select_admin_entite_juridique select', function (evt) {
        $(this).parents('form').first().submit();
    });
});
$(function(){
    if ($('.public-warning').first().length){
        $('html, body').animate({ scrollTop: $('.public-warning').first().offset().top }, '200');
    }
    if ($('.public-danger').first().length){
        $('html, body').animate({ scrollTop: $('.public-danger').first().offset().top }, '200');
    }
});

// Copy text
$(function(){
    $(document).on('click', '.copytext', function(evt){
        navigator.clipboard.writeText($(this).data('text'));
        if ($(this).data('message')) {
            alert($(this).data('message'));
        }
    });
});

// Trigger checkbox
$(function(){
    var triggerCheckbox = function(input) {
        if (input.is(':checked')) {
            $('.trigger-checkbox-field').css('opacity', '1');
        } else {
            $('.trigger-checkbox-field').css('opacity', '0.3');
        }
    }
    triggerCheckbox($('.trigger-checkbox input'));
    $(document).on('click', '.trigger-checkbox input', function(evt){
        triggerCheckbox($(this));
    });
});

// Trigger telephone
function loadTelephones() {
    $('.telephone').intlTelInput({
        formatOnDisplay: true,
        initialCountry: "fr",
        preferredCountries: ["fr"],
        separateDialCode: true,
        nationalMode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
    });
}
// Launch the function for the first time
$(function(){
    $('#change_telephone_form_telephone, #repeated_email_telephone_telephone, #personne_transfer_telephone, #change_telephone_form_telephone, #change_telephone_password_form_telephone').intlTelInput({
        initialCountry: "fr",
        preferredCountries: ["fr"],
        nationalMode: true,
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
    });
});


// Update the telephone
//force tp add IP if role != patient + Clean IPs if role = patient
$('body').on('submit', '#change_telephone_form, #repeated_email_telephone', function(evt) {
    var inputItem = $(this).find('.telephone_wrapper input').first();
    inputItem.val(inputItem.intlTelInput("getNumber"));
});

// Avoid double submit on certain forms
$(document).on('submit', '.form-submit-double', function(evt){
    if ($(this).hasClass('submitting')) {
        evt.preventDefault();
    } else {
        $(this).addClass('submitting')
    }
});

// Try to check the payment every 20 seconds
if ($('.loading_payment').length > 0) {
    var urlPayment = $('.loading_payment').data('url');
    var urlPaymentSuccess = $('.loading_payment').data('urlsuccess');
    var intervalPayment = window.setInterval(function(){
        $.ajax({
            url: urlPayment,
            success: function(response) {
                if (response.status && response.status == 'OK') {
                    window.location = urlPaymentSuccess;
                }
            }
        });
      }, 20000);
}