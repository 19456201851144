var siteLocale = document.documentElement.lang;

switch (siteLocale) {
    default:
    case 'en':
        var messageHtmlLoading = '<div class="alert alert-info">Loading...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Sorry, there was a problem with your request. Try to reconnect.</div>';
        var messageAreYouSure = 'Are you sure?';
        break;
    case 'fr':
        var messageHtmlLoading = '<div class="alert alert-info">Chargement en cours...</div>';
        var messageHtmlErrorConnection = '<div class="alert alert-info">Désolé, il y a eu un probleme avec votre demande. Essayez de vous reconnecter.</div>';
        var messageAreYouSure = 'Etes-vous sûr ?';
        break;
}

// Action to delete a shared person
$(document).on('click', '.shared-person-delete', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    var numberOfItems = $('.table .shared-person-field').length;
    if (confirm(messageAreYouSure)) {
        $('.shared-persons-message').html('');
        var field = $(this).parents('.shared-person-field').first();
        $.ajax({
            url: $(this).data('url'),
            beforeSend: function() {
                $('.shared-persons-ins').addClass('hide');
                $('.shared-persons-message').html(messageHtmlLoading);
                $('.shared-persons-message').removeClass('hide');
            },
            error: function() {
                $('.shared-persons-message').html(messageHtmlErrorConnection);
                $('.shared-persons-message').removeClass('hide');
                $('.shared-persons-ins').removeClass('hide');
            },
            success: function(data) {
                if (numberOfItems == 10) {
                    location.reload();
                } else {
                    $('.shared-persons-ins').removeClass('hide');
                    if (data.deleted) {
                        $('.shared-persons-message').addClass('hide');
                        field.remove();
                    } else {
                        $('.shared-persons-message').html(messageHtmlErrorConnection);
                        $('.shared-persons-message').removeClass('hide');
                    }
                }
            }
        });
    }
});

// Action to add a shared person
$(document).on('click', '.shared-persons-add', function (evt) {
    evt.stopImmediatePropagation();
    $('.shared-persons-list').addClass('hide');
    $('.shared-persons-add-section').removeClass('hide');
});

// Action to cancel the adding of a shared person
$(document).on('click', '.shared-persons-add-cancel', function (evt) {
    evt.stopImmediatePropagation();
    $('.shared-persons-list').removeClass('hide');
    $('.shared-persons-add-section').addClass('hide');
});

// Action to close the share modal
$(document).on('click', '.share-cancel', function (evt) {
    evt.stopImmediatePropagation();
    $('#modal-share-person').modal('hide');
});

// Action to close the code generated window
$(document).on('click', '.shared-persons-list-back', function (evt) {
    evt.stopImmediatePropagation();
    $('.shared-persons-list').removeClass('hide');
    $('.shared-persons-add-section').addClass('hide');
    $('.shared-persons-message').addClass('hide');
    $('.shared-persons-form').removeClass('hide');
});

// ADMIN AREA
$(document).on('click', '.badge-shared', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    $.ajax($(this).data('url'))
        .done(function(data) {
            var html = (data.html) ? data.html : messageHtmlErrorConnection;
            $('#modal-edit-share .modal-body').html(html);
            $('#modal-edit-share').modal();
        });
});

$(document).on('click', '.close-modal-share', function (evt) {
    $('#modal-edit-share').modal('hide');
});

$(document).on('click', '.btn-delete-share', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();
    if (confirm(messageAreYouSure)) {
        $.ajax($(this).data('url'))
            .done(function(data) {
                if (data.deleted) {
                    location.reload();
                } else {
                    var html = (data.html) ? data.html : messageHtmlErrorConnection;
                    $('#modal-edit-share .modal-body').html(html);
                }
            });
    }
});
